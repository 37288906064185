/* eslint-disable default-case */
import produce from 'immer'
import { DEFAULT_VIEWPORT, MAP_TRACK_SOURCE, MAP_URL } from 'common/Map/const'
import { transformRequest } from 'utils/helpers'

// Action Types
export const UPDATE_VIEWPORT = 'map/UPDATE_VIEWPORT'
export const UPDATE_TRACKED_SOURCE = 'map/UPDATE_TRACKED_SOURCE'
export const UPDATE_DISPLAYED_LAYERS = 'map/UPDATE_DISPLAYED_LAYERS'

// Reducer
export const initialState = {
  viewport: {
    ...DEFAULT_VIEWPORT,
    transformRequest: (url, resourceType) => transformRequest(url, resourceType, MAP_URL),
  },
  trackedSource: MAP_TRACK_SOURCE.schematic,
  displayedLayers: [],
}

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_VIEWPORT:
        draft.viewport.width = action.viewport.width
        draft.viewport.height = action.viewport.height
        draft.viewport.latitude = action.viewport.latitude
        draft.viewport.longitude = action.viewport.longitude
        draft.viewport.zoom = action.viewport.zoom
        draft.viewport.bearing = action.viewport.bearing
        draft.viewport.pitch = action.viewport.pitch
        draft.viewport.transitionDuration = action.viewport.transitionDuration
        draft.viewport.transitionInterpolator = action.viewport.transitionInterpolator
        break
      case UPDATE_TRACKED_SOURCE:
        draft.trackedSource = action.trackedSource
        break
      case UPDATE_DISPLAYED_LAYERS:
        draft.displayedLayers = action.displayedLayers
        break
    }
  })
}

// Functions
export const updateViewport = viewport => dispatch => {
  dispatch({
    type: UPDATE_VIEWPORT,
    viewport,
  })
}

export const updateTrackedSource = trackedSource => dispatch => {
  dispatch({
    type: UPDATE_TRACKED_SOURCE,
    trackedSource,
  })
}

export const updateDisplayedLayers = displayedLayers => dispatch => {
  dispatch({
    type: UPDATE_DISPLAYED_LAYERS,
    displayedLayers,
  })
}
