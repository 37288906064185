/* eslint-disable */
import React, { useEffect, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom'
import { auth } from '@osrdata/app_core'
import Loader from 'common/Loader'
import { hideError } from "reducers/app"

import 'App.scss'
import logo from 'assets/logos/logo-DGEXSOL-white.svg'
import Mtbf from 'applications/Mtbf/Mtbf'
import history from './history'
import {
  CSSTransition
} from "react-transition-group"
import TopBar from 'components/TopBar'
export default function App() {
  const dispatch = useDispatch()
  const { isLogged, appPermissions } = useSelector(state => state.user)
  const { error, errorMessage } = useSelector(
    (state) => state.app
  )

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  function defrailAccess() {
    return appPermissions.includes('defrail::ACCESS')
  }

  function closeError() {
    dispatch(hideError());
  }

  return (
    <>
      <CSSTransition in={error} timeout={300} classNames="warning" unmountOnExit>
        <div className="form-error bg-warning text-dark mb-3">
          <h2 className="text-uppercase text-dark">Attention</h2>
          <div>{errorMessage}</div>
          <button
            type="button"
            onClick={closeError}
            className="btn btn-outline-dark btn-sm mt-3"
          >
            Fermer
          </button>
        </div>
      </CSSTransition>
      <Suspense fallback={<Loader />}>
        {isLogged && (
          <Router history={history}>
            <TopBar appName="" logo={logo} />
            <Switch>
              <Route exact path="/mtbf" component={Mtbf} />
            </Switch>
            <Redirect to="/mtbf" />
          </Router>
        )}
      </Suspense>
    </>
  )
}
