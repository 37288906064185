/* eslint-disable */

import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import { auth } from '@osrdata/app_core'

import { Link, matchPath, withRouter } from 'react-router-dom'

import { PORTAL_URL } from 'config/config'
import logo from 'assets/logos/logo-DGEXSOL-white.svg'
import { FaPowerOff } from 'react-icons/fa'

function TopBar(props) {
	const dispatch = useDispatch()
	const { location } = useSelector(state => state.app)
	const { account } = useSelector(state => state.user)
	const { appPermissions } = useSelector(state => state.user)
	const handleLogout = () => {
		dispatch(auth.logout())
	}
	function defrailAccess() {
		return appPermissions.includes('defrail::ACCESS')
	  }

	return (
		<div className={`mastheader`}>
			<div className="mastheader-logo">
				<a href={PORTAL_URL.url}><img alt={props.appName} src={logo} width="70" /></a>
			</div>
			<header role="banner" className="">
				<h1 className="text-uppercase text-white pl-3 mb-0">{props.appName}</h1>
			</header>
			<ul className="mastheader-toolbar toolbar ml-10 mb-0 mr-auto">
				<li className="toolbar-item separator-gray-500">
					<Dropdown>
						<Dropdown.Toggle variant="transparent">
							{location}
							<i className="icons-arrow-down d-none d-xl-block ml-3" aria-hidden="true" />
						</Dropdown.Toggle>
						<Dropdown.Menu className='drop-down-topbar'>
							<Dropdown.Item className="d-flex">
								<Link
									to="/mtbf"
									className="w-100"
								>DEFRAIL</Link>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</li>
			</ul>
			<ul className="mastheader-toolbar toolbar mb-0">
				<li className="toolbar-item separator-gray-500">
					<Dropdown>
						<Dropdown.Toggle variant="transparent">
							<i className="icons-menu-account icons-size-1x25 icons-md-size-1x5 mr-xl-2" aria-hidden="true" />
							<span className="d-none d-xl-block">
								{account.firstName}
								{' '}
								{account.lastName}
							</span>
							<i className="icons-arrow-down d-none d-xl-block" aria-hidden="true" />
						</Dropdown.Toggle>
						<Dropdown.Menu className='drop-down-topbar'>
							<Dropdown.Item onClick={handleLogout}>
								<span className="mr-2"><FaPowerOff /></span>
								Se déconnecter
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</li>
			</ul>
		</div>
	)
}

export default TopBar
