import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'
import persistedReducer from 'reducers'

const store = createStore(persistedReducer, composeWithDevTools(
  applyMiddleware(thunk),
  // other store enhancers if any
))

const persistor = persistStore(store)

export {
  store,
  persistor,
}
