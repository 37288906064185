/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactMapGL, {
  Popup as MapPopup,
} from "react-map-gl";

import { updateViewport } from 'reducers/map'
import { updateLocation } from 'reducers/app'
import mapStyleJSON from 'assets/mapstyles/style_osrd_light.json'
// import mapStyleJSON from 'assets/mapstyles/style_osrd_blank.json'
// import mapStyleJSON from 'assets/mapstyles/style_osrd_empty.json'
import MtbfView from 'components/Map/MtbfView'
import GeographicView from 'components/Map/GeographicView'
import 'applications/apps.scss'

const Mtbf = () => {
  const dispatch = useDispatch()
  const { viewport } = useSelector(state => state.map)
  const [showPopup, togglePopup] = useState(false)
  const [featureInfoHover, setFeatureInfoHover] = useState(undefined)

  const onViewportChange = newViewport => {
    dispatch(updateViewport(newViewport))
  }
  useEffect(() => {
    dispatch(updateLocation('DEFRAIL'));
  }, []);

  const onFeatureClick = e => {
    if (e?.features?.length > 0) {
      setFeatureInfoHover(e)
      togglePopup(true)
    }
  }

  function popupContent() {
    return (
      <>
        <div className={`mapboxgl-popup-title d-flex align-items-center justify-content-between bg-secondary`}>
          <strong>LIGNE: {featureInfoHover.features[0].properties.ligne}</strong>
          <span>{featureInfoHover.features[0].properties.pkd} / {featureInfoHover.features[0].properties.pkf}</span>

        </div>
        <div className="mapboxgl-popup-container">
          
          <div className='mb-2'><strong>MTBF :  </strong>{featureInfoHover.features[0].properties.mtbf}</div>

          <div><strong>Famille la plus présent : </strong>{featureInfoHover.features[0].properties.mpf}</div>
          <div className='mb-2'><strong>Classe plus récurrent : </strong>{featureInfoHover.features[0].properties.mrc}</div>

          <div><strong>Année pose : </strong>{featureInfoHover.features[0].properties.annee_pose}</div>
          <div><strong>Vitesse : </strong>{featureInfoHover.features[0].properties.vitesse}</div>
          <div className='mb-2'><strong>UIC : </strong>{featureInfoHover.features[0].properties.uic}</div>

          <div><strong>Profil : </strong>{featureInfoHover.features[0].properties.profil}</div>
          <div><strong>Nuance : </strong>{featureInfoHover.features[0].properties.nuance}</div>

        </div>
      </>
    )
  }

   return (
    <>
      <div style={{ width: '100%', height: '100vh' }}>
        <ReactMapGL
          {...viewport}
          style={{ cursor: 'pointer' }}
          width="100%"
          height="100%"
          mapStyle={mapStyleJSON}
          onViewportChange={onViewportChange}
          onClick={onFeatureClick}
          attributionControl={false}
          clickRadius={2}
        >
          <GeographicView />
          <MtbfView />
          {showPopup && (<MapPopup
            longitude={featureInfoHover?.lngLat[0]}
            latitude={featureInfoHover?.lngLat[1]}
            closeButton={true}
            captureScroll={true}
            onClose={() => togglePopup(false)}
            className="mapboxgl-hover-custom-popup"
          >{popupContent()}</MapPopup>)}
        </ReactMapGL>

      </div>
    </>
  )
}

export default Mtbf
