import React from 'react'
import { Source, Layer } from 'react-map-gl'
import { mtbflayer } from 'common/Map/Layers/mtbflayers.json'
import { mtbflegend } from 'common/Map/Layers/mtbflegend.json'
import mtbf1 from 'data/mtbf_1.json'
import mtbf2 from 'data/mtbf_2.json'
import mtbf3 from 'data/mtbf_3.json'
import mtbf4 from 'data/mtbf_4.json'

function MtbfView() {
  return (
    <>
      <Source
        type="geojson"
        data={mtbf1}
      >
        <Layer
          {...mtbflayer}
          layout={{ visibility: 'visible' }}
          id="mtbf1"
        />
      </Source>
      <Source
        type="geojson"
        data={mtbf2}
      >
        <Layer
          {...mtbflayer}
          layout={{ visibility: 'visible' }}
          id="mtbf2"
        />
      </Source>
      <Source
        type="geojson"
        data={mtbf3}
      >
        <Layer
          {...mtbflayer}
          layout={{ visibility: 'visible' }}
          id="mtbf3"
        />
      </Source>
      <Source
        type="geojson"
        data={mtbf4}
      >
        <Layer
          {...mtbflayer}
          layout={{ visibility: 'visible' }}
          id="mtbf4"
        />
      </Source>
      <div id="state-legend" className="legend">
        <span>MTBF&nbsp;&nbsp;&nbsp;&nbsp;</span>
        {mtbflegend.map(l => (
          <div
            key={l.mtbf}
          >
            <span
              style={{ backgroundColor: l.color }}
            />
            {l.mtbf}
          </div>
        ))}
      </div>
    </>
  )
}

export default MtbfView
