import React from 'react'
import PropTypes from 'prop-types'

const Loader = ({
  msg, center,
}) => {
  const className = center ? 'loader center' : 'loader'

  return (
    <div className={className}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="text-center mt-2">{msg}</div>
    </div>
  )
}

Loader.propTypes = {
  msg: PropTypes.string,
  center: PropTypes.bool,
}

Loader.defaultProps = {
  msg: '',
  center: false,
}

export default Loader
