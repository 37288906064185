import config from 'config/config'

export const MAP_URL = config.proxy

export const DEFAULT_VIEWPORT = {
  latitude: 46.8025,
  longitude: 2.7643,
  zoom: 5.4389429465554,
  bearing: 0,
  pitch: 0,
}

export const MAP_TRACK_SOURCE = {
  schematic: 'map_midi_tronconditinerairevoie_schematic',
  geographic: 'map_midi_tronconditinerairevoie_geographic',
  lineGeographic: 'phrit_ligne',
}
