export const OBJECTS_LAYERS = {
  tivGeo: 'map_midi_tronconditinerairevoie',
  armen: 'armen_troncon',
  pmSigPr: 'pm_sig_pr',
  pmSigSegment1km: 'pm_sig_segments_un_km',
  pmSigSegment5km: 'pm_sig_segments_cinq_km',
  pmSigSegment10km: 'pm_sig_segments_dix_km',
  pmSigSegmentStrategique: 'pm_sig_segments_strategique',

}
export default OBJECTS_LAYERS
